import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"
import styled from "styled-components"

const timeout = 250
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}

const TransitionWrapper = styled.div`
  margin-left: 100px;
  max-width: 960px;

  @media (max-width: 767px) {
    margin-left: 0;
    padding-top: 25px;
  }
`

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <TransitionWrapper
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </TransitionWrapper>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
